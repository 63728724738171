<script lang="jsx">
export default {
  name: 'CamelCasePrice',
  functional: true,
  props: {
    // 是否使用驼峰样式
    showCamelPrice: {
      type: Boolean,
      default: false,
    },
    // 原始价格字符串
    originString: {
      type: String,
      default: '',
    },
    // 币种符号前缀
    currencySymbolPrefix: {
      type: String,
      default: ''
    },
    // 币种符号后缀
    currencySymbolSuffix: {
      type: String,
      default: ''
    },
    // 小数点前金额
    numberBeforeDot: {
      type: [String, Number],
      default: ''
    },
    // 小数点后金额
    numberAfterDot: {
      type: String,
      default: ''
    },
    // 驼峰价格点前后fontSize
    camelPriceStyle: {
      type: Object,
      default: () => {}
    }
  },
  render(h, obj) {
    const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix, showCamelPrice, originString, camelPriceStyle = {} } = obj.props || {}
    const camelHtml = (<p class="product-card__camel-case-price" {...obj.data} style={ camelPriceStyle?.after }>
      <span class="product-card__camel-case-price__prefix-symbol">
        {currencySymbolPrefix}
      </span>
      <span class="product-card__camel-case-price__number-before-dot" style={ camelPriceStyle?.before }>
        {numberBeforeDot}
      </span>
      <span class="product-card__camel-case-price__number-after-dot">
        {numberAfterDot}
      </span>
      <span class="product-card__camel-case-price__suffix-symbol">
        {currencySymbolSuffix}
      </span>
    </p>)
    const notCamelPrice = (
      <span class="product-card__no-camel-case-price" {...obj.data}>
        {originString}
      </span>
    )
    const result = showCamelPrice ? camelHtml : notCamelPrice
    return result
  },
}
</script>

<style lang="less">
.product-card {
  &__camel-case-price {
    .flexbox();
    align-items: baseline;
    height: 0.5333rem;
    line-height: 0.5333rem;
    font-size: .293rem;
    /* stylelint-disable-next-line */
    &__number-before-dot {
      font-size: .453rem;
    }
  }

  &__no-camel-case-price {
    flex: 1;
    .text-overflow();
  }
}
</style>
